import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";
import { Link } from "react-router-dom";
import { getCountries, registerPhoneNumber } from "../../utils/RegisterNumber";

export default function VerifyPhone() {
  const [countryState, setCountryState] = useState({
    loading: false,
    countries: [],
    errorMessage: "",
  });

  const [selectedCountry, setSelectedCountry] = useState("Oman");
  const [phoneNumber, setPhoneNumber] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        // fetch spinner
        setCountryState({
          ...countryState,
          loading: true,
        });

        // Fetch countries using the getCountries function from utils.js
        const countries = await getCountries();
        setCountryState({
          ...countryState,
          countries,
          loading: false,
        });
      } catch (error) {
        setCountryState({
          ...countryState,
          loading: false,
          errorMessage: "Sorry, something went wrong",
        });
      }
    };

    fetchData();
  }, []);
  
  
  
  const { loading, errorMessage, countries } = countryState;

  const searchSelectedCountry = countries.find((obj) => {
    if (obj.name.common === selectedCountry) {
      return true;
      // console.log("loading", loading);
    }
    return false;
  });
  // console.log("searchSelectedCountry", searchSelectedCountry);

// handling here the phone regestration 
const handleVerifyPhoneNumber = async () => {
  try {
    // Call the registerPhoneNumber function from utils.js
    const token = await registerPhoneNumber(selectedCountry, phoneNumber);

    // Now you have the token from the API response, you can handle it as required (e.g., save it in cookies)
    // Save token in cookies, you can use a library like js-cookie or any other preferred method
    // For example, we've already saved it in cookies in the registerPhoneNumber function
    console.log("Token:", token);

    // Redirect to another page or perform any action with the token as needed
    // e.g., history.push("/some-page");

  } catch (error) {
    // Handle any errors that occur during phone number registration
    console.error("Error verifying phone number:", error.message);
  }
};

  return (
    <>
      <div className="xs:min-h-screen ">
        <div className=" lg:hidden  --logo flex justify-center xs:mt-[3rem]">
          <img
            src={Logo}
            alt=""
            className="lg:w-[134px] xs:h-[49px] bounce-in-top  "
          />
        </div>
        <div className="flex flex-row justify-start  ">
          <div className="xs:hidden item-1 h-screen lg:w-[100vh] bg-[#cb22290f]">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-9">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[7rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          <div className="item-2 bg-[#cb22290f] xs:bg-white w-[50%] flex justify-center xs:w-[100%]">
            <div className="bg-white xs:bg-[#cb22290f] shdaow-md w-[88%] rounded-md  mt-[9rem] xs:mt-8 h-[513px] drop-c fade-in-right ">
              <h1 className="text-center font-bold text-[22px] mb-[6rem] relative top-[33px] xs:font-all ">
                Verify your phone number
              </h1>
              <div className="bg-white xs:bg-[#fcf2f2] w-auto  sm:w-full md:w-4/5 md:mx-auto lg:w-2/5 lg:mx-auto">
                {/* header section */}

                {/* body section */}
                <div>
                  {loading === true ? (
                    <div className="flex justify-center items-center h-48 ">
                      <p className="uppercase font-bold text-3xl">...loading</p>
                    </div>
                  ) : (
                    <div className="grid justify-center mt-14 mx-10 space-y-10">
                      <div>
                        <select
                          value={selectedCountry}
                          onChange={(e) => setSelectedCountry(e.target.value)}
                          className="w-[16rem]  h-14 text-xl rounded-lg md:text-2xl "
                        >
                          <option>--Select Country--</option>
                          {countries.map((item) => {
                            return (
                              <option
                                className="w-[16rem]"
                                key={uuidv4()}
                                value={item.name.common}
                              >
                                {item.name.common}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div>
                        {searchSelectedCountry && (
                          <div className="flex space-x-4">
                            <div className="flex items-end border-b-2 border-gray-500 pb-2">
                              <img
                                className="w-16 h-8"
                                src={
                                  searchSelectedCountry &&
                                  searchSelectedCountry.flags.png
                                }
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="h-14 text-xl flex items-end border-b-2 border-gray-500 pb-3 md:text-2xl">
                                {searchSelectedCountry &&
                                  searchSelectedCountry.idd.root}
                                {searchSelectedCountry &&
                                  searchSelectedCountry.idd.suffixes}
                              </p>
                            </div>
                            <div>
                            <input
                  type="tel"
                  placeholder="Phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="bg-[#fcf2f2] w-full h-14 text-xl border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-white focus:border-b-2 focus:outline-none md:text-2xl xs:border-b  border-gray-500"
                />

                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/*  */}
             
                {" "}
                <button  onClick={handleVerifyPhoneNumber} className="lg:mt-[11rem] xs:mt-[6rem] flex justify-center items-center mx-auto m-auto bg-[#CB2229] text-white rounded-full font-all p-[6px]  w-[18rem]   transform hover:scale-105 duration-500 ease-in-out ">
                  Verify
                </button>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
